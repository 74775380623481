import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "./body.css";
import "antd/dist/antd.css";
import { ApolloProvider } from "@apollo/react-hooks";
import { Layout } from "antd";
import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import client from "./graphql/client";
import Header from "./components/Header";
import WebSocketTransport from '@cubejs-client/ws-transport';
const Auth_API = "https://auth.tarf.io/auth"



const queryString = require('query-string');

 console.log(window.location.search);


const parsed = queryString.parse(window.location.search);

console.log("token from dashboard is : " , parsed.token);


 console.log(window.location.hash);





const cubejsApi = cubejs({
      transport: new WebSocketTransport({
        authorization: parsed.token,
        
        apiUrl: 'wss://prod-cubejs.tarf.io/' 

      })
});


const AppLayout = ({ children }) => (
  <Layout
    style={{
      height: "100%"
    }}
  >
    <Header />
    <Layout.Content>{children}</Layout.Content>
  </Layout>
);

const App = ({ children }) => (
  <CubeProvider cubejsApi={cubejsApi}>
    <ApolloProvider client={client}>
      <AppLayout>{children}</AppLayout>
    </ApolloProvider>
  </CubeProvider>
);

export default App;
