import React from 'react'
import { CSVLink, CSVDownload } from "react-csv";

import { Button, Radio } from 'antd';
import {DownloadOutlined}  from '@ant-design/icons';



 
const Export = ({data}) => (


<CSVLink
  data={data}
  filename={"Production Data.csv"}
//   className="btn btn-primary"
  target="_blank"
>
<Button type="primary" shape="round" icon={<DownloadOutlined />} size={'large'}>
Download
</Button>
<div></div>
</CSVLink>

);

export default Export

